<template>
  <div>
    <spinner v-if="loading" />
    <template v-else>
      <template v-if="getWritingGroupPages.length > 0">
        <SortableListGroup
          :count="countItems(group.ID)"
          v-for="group in getWritingGroupPages"
          :key="group.ID"
          :item="group"
        >
          <SortableListGroupItems :group="group.ID" />
        </SortableListGroup>
        <SortableListNoGroupItems />
      </template>
      <SortableListAllItems v-else />
    </template>
  </div>
</template>

<script>
import SortableListGroup from "./SortableListGroup";
import { mapGetters } from "vuex";
import SortableListGroupItems from "./SortableListGroupItems";
import SortableListNoGroupItems from "./SortableListNoGroupItems";
import SortableListAllItems from "./SortableListAllItems.vue";
import Spinner from "./Spinner";

export default {
  components: {
    SortableListGroup,
    SortableListGroupItems,
    SortableListAllItems,
    SortableListNoGroupItems,
    Spinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["getWritingGroupPages", "getLegacyJournal"]),
  },

  methods: {
    loadJournal() {
      this.$store.dispatch("loadWritingsGroupPages", () => {
        this.$store.dispatch("loadLegacyJournal", [
          "1",
          () => {
            this.loading = false;
          },
        ]);
      });
    },
    countItems(group) {
      return (
        this.getLegacyJournal
          .filter((j) => parseInt(j.writing_group) === group)
          .filter((j) => j.legacy_journal).length > 0
      );
    },
  },
  mounted() {
    this.$store.dispatch("loadDefaults", [
      [
        "default_font_family",
        "default_font_size",
        "image_upload_limit",
        "image_upload_count",
      ],
      () => {
        this.loadJournal();
      },
    ]);
  },
};
</script>