<template>
  <div class="btn-group btn-group-sm float-right" role="group">
    <button
      type="button"
      class="btn btn-sm btn-secondary btn-move-up"
      @click="moveUp"
      :class="{ noEditButton: !showEditButton }"
    >
      <i class="fa fa-caret-up" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary btn-move-down"
      @click="moveDown"
    >
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success"
      @click="triggerModal"
      v-if="showEditButton"
    >
      Edit
    </button>
  </div>
</template>

<script>
import PageEditorModal from "./PageEditorModal";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showModal: false,
      showEditButton: true,
    };
  },
  computed: {
    ...mapGetters(["getLegacyJournal", "getWritingGroupPages"]),
    groupIds() {
      const ids = [];
      this.getWritingGroupPages.map((p) => ids.push(p.ID));
      return ids;
    },
    items: {
      get() {
        if (this.group !== 0) {
          return this.getLegacyJournal
            .filter((j) => parseInt(j.writing_group) === this.group)
            .sort((a, b) => a.menu_order - b.menu_order);
        } else {
          return this.getLegacyJournal
            .filter(
              (j) => this.groupIds.indexOf(parseInt(j.writing_group)) === -1
            )
            .sort((a, b) => a.menu_order - b.menu_order);
        }
      },
      set(value) {
        value.map((item, index) => {
          item.menu_order = index;
        });
      },
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    group: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    moveUp() {
      const new_index = this.index - 1 < 0 ? 0 : this.index - 1;
      this.moveItem(this.index, new_index);
      this.updatePosition();
    },
    moveDown() {
      const new_index = this.index + 1;
      this.moveItem(this.index, new_index);
      this.updatePosition();
    },
    moveItem(from, to) {
      const items = this.items;
      var f = items.splice(from, 1)[0];
      items.splice(to, 0, f);
      this.items = items;
    },
    updatePosition() {
      this.$store.dispatch("updateLegacyJournalPositionByGroup", [
        this.items,
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        },
      ]);
    },
    triggerModal() {
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
          closeTo: "nothing",
        },
        {
          name: "sortable-list-group-modal-" + this.item.ID,
          resizable: true,
          minWidth: 880,
          minHeight: 605,
        }
      );
    },
  },
  mounted() {
    //console.log( this.item );
    // console.log(this.items, this.group);
  },
};
</script>
<style scoped>
.legacy-journal-container
  .list-group
  .list-group-item:last-child
  .btn-move-up.noEditButton {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
</style>