<template>
  <div v-if="count">
    <h3>{{ item.post_title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {
          post_title: "",
        };
      },
    },
    count: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped>
</style>