<template>
  <div>
    <draggable
      class="list-group"
      v-model="filteredItems"
      group="filteredItems"
      @start="drag = true"
      @end="drag = false"
      handle=".handle"
      @change="updatePosition"
    >
      <Tooltip
        message="You may drag and drop..."
        :drag="drag"
        class="list-group-item"
        v-for="(item, index) in filteredItems"
        :index="index"
        :key="item.ID"
      >
        <SortableListGroupItem
          :item="item"
          :index="index"
          class="handle"
          :group="group"
        />
      </Tooltip>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SortableListGroupItem from "./SortableListGroupItem";
import Tooltip from "./Tooltip";
import { mapGetters } from "vuex";

export default {
  components: {
    draggable,
    SortableListGroupItem,
    Tooltip,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    group: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    ...mapGetters(["getLegacyJournal"]),
    filteredItems: {
      get() {
        return this.getLegacyJournal
          .filter((j) => parseInt(j.writing_group) === this.group)
          .filter((j) => j.legacy_journal)
          .sort((a, b) => a.menu_order - b.menu_order);
      },
      set(value) {
        value.map((item, index) => {
          item.menu_order = index;
        });
      },
    },
  },
  mounted() {},
  methods: {
    updatePosition() {
      this.$store.dispatch("updateLegacyJournalPositionByGroup", [
        this.filteredItems,
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        },
      ]);
    },
  },
};
</script>
<style>
.handle {
  cursor: all-scroll;
}
</style>